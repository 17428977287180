export const emailRegexp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const getRegistrationEmail = (email: string) => ({
  subject: "New registration to Location admin",
  text: `Hey hey, a new user has registered to the location admin. Please reach out to sebastian@meinklub.app to verify the user.`,
  to: email,
});

export const emojiRegex = /[\p{Emoji}\u200d]+/gu;

export const max_query_batch = 30;